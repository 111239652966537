import ModalController from "./modal_controller";
import Swal from "sweetalert2";
import I18n from "../plugins/i18n";

export default class extends ModalController {
  static values = {
    nWords: Number
  }
  static targets = [ "originalTextInput" ]

  checkBeforeTranslation (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.hasOriginalTextInputTarget ? this.nWordsInput() < 5 : this.nWordsValue < 5) {
      Swal.fire({
        title: I18n.t('alert.error'),
        text: I18n.t('alert.minimum_words'),
        icon: 'error',
        showConfirmButton: false,
        showCloseButton: true
      })
      return false;
    } else {
      this.modalConfirm(nodeTarget => {
        nodeTarget.submit()
      }, this.element)
    }
  }

  nWordsInput () {
    const originalText = this.originalTextInputTarget.value
    const originalTextNumberWords = originalText.split(/\s/).filter(word => /\w/.test(word)).length
    return originalTextNumberWords
  }
}
