import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["alert", "notice"]

  alertTargetConnected = element => {
    this.autoclose(element)
  }

  noticeTargetConnected = element => {
    this.autoclose(element)
  }

  autoclose = target => {
    setTimeout(() => {
      target.classList.remove('show')
      setTimeout(() => {
        target.remove()
      }, 500)
    }, 5000)
  }
}

