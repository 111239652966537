import AriaController from "./aria_controller";
import I18n from "../plugins/i18n";
import Swal from "sweetalert2";

export default class extends AriaController {
  static values = {
    title: String,
    text: String,
    confirmText: String,
    cancelText: String
  }

  inviteUser = (e) => {
    const [response] = e.detail;
    Swal.fire({
      title: I18n.t('javascript.modal.invite.invite_user'),
      html: `<div id="invitation-modal"></div>`,
      showConfirmButton: false,
    })
    document.getElementById('invitation-modal').innerHTML = response.form;
  }

  turboModal = (e) => {
    Swal.fire({
      title: e.currentTarget.dataset.modalTitle,
      showCloseButton: true,
      showConfirmButton: false,
      html: `<turbo-frame id="${e.currentTarget.dataset.frameId}" ${e.currentTarget.dataset.frameAttributes || ''}></turbo-frame>`
    });
  }

  closeModal = (e) => {
    const currentTarget = document.getElementById(e.currentTarget.id)
    if (currentTarget.querySelector('.invalid-feedback') === null) {
      Swal.close();
    }
  }

  modalConfirm = (actionAfterConfirm, nodeTarget) => {
    Swal.fire({
      title: this.hasTitleValue ? this.titleValue : I18n.t('alert.are_you_sure'),
      text: this.hasTextValue ? this.textValue : '',
      confirmButtonText: this.hasConfirmTextValue ? this.confirmTextValue : I18n.t('alert.confirm'),
      cancelButtonText: this.hasCancelTextValue ? this.cancelTextValue : I18n.t('alert.cancel'),
      showCancelButton: true,
      customClass: {
        confirmButton: "btn-orange",
        cancelButton: "btn-light-orange ms-4"
      },
      buttonsStyling: false
    }).then(result => {
      if (result.isConfirmed) {
        actionAfterConfirm(nodeTarget)
      }
    })
  }

  confirmAndSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    this.modalConfirm(nodeTarget => {
      nodeTarget.requestSubmit()
    }, this.element)
  }
}
